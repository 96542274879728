<template>
  <v-container
    class="onyx leaf--text font-weight-light"
    style="display: flex; height: 100vh; align-items: center;"
    fluid
  >
    <v-btn
      fixed
      right
      top
      class="sign-in-button ma-3 text-uppercase"
      color="leaf leaf--text elevation-0"
      text
      :style="{margin: '2vmin 2vmin 0 0 !important'}"
      @click="skipOnboarding"
    >
      <span :style="{'color': colors.leaf, 'border-color': colors.leaf}">
        {{ isAuthenticated ? 'Skip' : 'Sign in' }}
      </span>
    </v-btn>
    <v-row align="center">
      <v-spacer />
      <v-col
        cols="9"
        text-xs-center
        :px-4="$vuetify.breakpoint.smAndUp"
        style="max-width: 500px"
        class="d-flex justify-center align-center"
      >
        <svgicon
          name="cpd_logo_light"
          class="mt-4 pb-4"
          style="max-width: 400px"
          width="100%"
          color="#f5f6f5 #00A25F"
        />
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="5"
        xl="4"
        :class="$vuetify.breakpoint.xsOnly && 'px-8'"
      >
        <div :style="$vuetify.breakpoint.smAndDown && 'max-width: 500px; margin: 0 auto;'">
          <p
            :style="{'font-size': windowScale(28,60)+'px'}"
            class="py-4 font-weight-light"
            style="my-test-class"
          >
            Find your path to being Climate Positive. Use the <span class="font-weight-medium">Pathfinder</span>.
          </p>
          <p class="body text-xs-center pt-3">
            <v-btn
              color="leaf"
              class="px-5 text-uppercase"
              outlined
              block
              large
              :href="`${windowOrigin}/commitment${isAuthenticated ? '?hasauth=1' : ''}`"
            >
              Get Started
            </v-btn>
          </p>
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-footer
      fixed
      color="transparent"
      align-center
    >
      <v-flex
        xs12
        align-center
        text-xs-left
        pl-5
      >
        <p style="font-size: 0.8em;color:#596767">
          v{{ pjson.version }}
        </p>
      </v-flex>
    </v-footer>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import '@/assets/icons/cpd_logo_light'
const pjson = require('../../../package.json')

export default {
  name: 'LandingPage',
  data () {
    return {
      pjson,
      windowOrigin: null
    }
  },
  computed: {
    ...mapState(['colors']),
    isAuthenticated () {
      return Boolean(this.$store.state.userId)
    }
  },
  created () {
    this.windowOrigin = window.location.origin
    this.$store.commit('setIsOnboarding', true)
  },
  methods: {
    windowScale (out_min, out_max) {
      return (this.$vuetify.breakpoint.width - 320) * (out_max - out_min) / (1920 - 320) + out_min
    },
    skipOnboarding () {
      this.$store.commit('setIsOnboarding', false)
      this.$router.push('/start')
    }
  }
}
</script>

<style lang="scss" scoped>

.my-test-class {
  font-weight: 100 !important;
}

</style>
